import { createRouter, createWebHistory } from "vue-router";
// import AppLayout from "@/layouts/App.vue";
import PublicLayout from "@/layouts/Public.vue";
import store from "@/store/index";

const routes = [
  {
    path: "",
    component: PublicLayout,
    // redirect: "/login",
    children: [
      {
        path: "",
        component: () =>
          import(/* webpackChunkName: "CustomerSurvey" */ "../views/pages/CustomerSurvey.vue"),
      },
    ],
  },
  // {
  //   path: "",
  //   component: AppLayout,
  //   meta: { auth: true },
  //   children: [
  //     {
  //       path: "/dashboard",
  //       component: () =>
  //         import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
  //     },
  //     {
  //       path: "/survey",
  //       component: () =>
  //         import(/* webpackChunkName: "survey" */ "../views/Surveys.vue"),
  //     },
  //   ],
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.auth) &&
    !store.getters["Auth/isLoggedIn"]
  ) {
    next("/login");
    return;
  }
  next();
});

export default router;
