<template>
  <el-row>
    <img src="@/assets/images/halda_bn.jpg" style="max-width: 100%;"/>
  </el-row>
</template>

<script>
export default {
  name: "PublicHeaderComp",
};
</script>

