<template>
  <div class="common-layout">
    <el-container>
      <el-header>
        <PublicHeaderComp />
      </el-header>

      <el-main class="main-container">
            <router-view />
          </el-main>
    </el-container>
  </div>
</template>
<script>
import PublicHeaderComp from '@/components/common/PublicHeader.vue'
export default {
  name: "PublicLayout",
  components: {
    PublicHeaderComp
  }
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;800&display=swap");
* {
  font-family: "Poppins", sans-serif;
}
body {
  background-color: #ebedef;
  margin: 0;
}
.el-header {
  width: 100%;
  height:auto;
  float:left;
  padding: 0;
}
</style>



